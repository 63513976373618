export const formatLargeNumber = (value, sp = true) => {
  let newVar = value.toString()
  const value_length = newVar.length
  if (value_length >= 4 && value_length <= 6) {
    newVar = (value / 1000).toFixed(0) + (sp ? ' ' : '') + 'k'
  } else if (value_length > 6) {
    newVar = (value / 1000000).toFixed(1) + (sp ? ' ' : '') + 'M'
  } else if (value_length > 9) {
    newVar = (value / 1000000000).toFixed(1) + (sp ? ' ' : '') + 'G'
  } else {
    newVar += ' '
  }
  return newVar
}

export const currency = (value) => {
  if (value) {
    return formatLargeNumber(value) + '€'
  } else {
    return ''
  }
}

export const largeAmount = (value) => {
  if (value != null && typeof value != 'undefined') {
    return formatLargeNumber(value, false)
  } else {
    return ''
  }
}
